import {AccessibilityIcon} from "./Icons";

const AccessibilityButton = ({href, target = "_self", variant = 'light', iconStroke = "#213165"}) => {
    return (
        <a
            className={`button button--features ${variant}`}
            href={href}
            target={target}
            aria-label="Open PDF bestand: inspectie-wbp6hhdelflandnl-11-2024.pdf"
        >
            <AccessibilityIcon stroke={iconStroke} />
            <span className="button__label">Toegankelijkheid</span>
        </a>
    )
}
export default AccessibilityButton;