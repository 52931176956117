import React, {useState, useEffect} from "react";
import Header from "./Header";
import {getAccessibilityData} from "../api";
import AccessibilityButton from "./AccessibilityButton";
import HeadMeta from "./HeadMeta";

function AccessibilityDetail() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {title, inhoud} = data || {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAccessibilityData();
                setData(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="AccessibilityDetail">
            <HeadMeta pageTitle={"Toegankelijkheidsverklaring | Waterwerk WBP6 Hoogheemraadschap van Delfland"} />
            <Header/>
            <div className="AccessibilityDetail__content">
                <div className="container">
                    <div className="row">
                        <div className="col-l-2o3 offset-l-1o6">
                            {title && <h1 className="h1 uppercase">{title}</h1>}
                            <div className="AccessibilityDetail__html" dangerouslySetInnerHTML={{__html: inhoud?.tekst}}/>
                            <div className="AccessibilityDetail__button">
                                <AccessibilityButton
                                    href="/files/wcag-inspectie-wbp6hhdelflandnl-11-2024.pdf"
                                    target="_blank"
                                    variant="dark"
                                    iconStroke="#fff"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccessibilityDetail;
