import React from 'react';
import LogoDelfslandComponent from "../components/LogoDelfsland";
import SkipLink from "../components/SkipLink";
import WaterwerkLogoDark from "../components/WaterwerkLogoDark";
import { Link } from "react-router-dom";

const Header = () => (
    <>
        <SkipLink targetId="main-content" />
        <header className="ClusterIndex-header">
            <div className="container">
                <div className="row">
                    <div className="col-1o2">
                        <LogoDelfslandComponent />
                    </div>
                    <div className="col-1o2 text-align-right">
                        <Link to="/" aria-hidden={true} tabIndex="-1">
                            <WaterwerkLogoDark />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    </>
);

export default Header;
