import axios from "axios";

export const getAreas = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}meta/Wbp6Gebied`)
    .then(({ data }) => data);
};

export const getFacets = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}content/wbp6/facet/thema`)
    .then(({ data }) => {
      return data;
    });
};

export const getDispositionFacets = async (query = "", areas = []) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}content/wbp6/facet/maatregel?limit=300${
        areas.length > 0
          ? "?" + areas.map(({ id }) => "facet=" + id).join("&")
          : ""
      }${
        query.trim() !== ""
          ? (areas.length > 0 ? "&" : "?") + `text=${query}`
          : ""
      }`
    )
    .then(({ data }) => {
      return data;
    });
};

export const getDevelopments = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}meta/Wbp6OntwikkelingLangeTermijn`)
    .then(({ data }) => data);
};

export const getThemes = async (
  filters = [],
  developments = [],
  cancelToken = null
) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}content/wbp6/list/thema${
        filters.length === 0
          ? ""
          : "?" + filters.map(({ id }) => "facet=" + id).join("&")
      }${
        developments.length === 0
          ? ""
          : (filters.length === 0 ? "?" : "&") +
            developments.map(({ id }) => "facet=" + id).join("&")
      }`,
      { cancelToken }
    )
    .then(({ data }) => {
      return data;
    });
};

export const getTheme = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}content/wbp6/${id}/thema`)
    .then(({ data }) => {
      return data;
    });
};

export const getDisposition = async (id) => {
    if (!id) {
        return null;
    }

    try {
        const [dispositionResponse, themeResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_HOST}content/wbp6/${id}/maatregel?limit=300`),
            axios.get(`${process.env.REACT_APP_HOST}content/wbp6/list/thema`)  // Fetch theme data
        ]);

        if (dispositionResponse.status === 404 || themeResponse.status === 404) {
            throw new Error("Resource not found: 404 error");
        }

        const disposition = dispositionResponse.data;
        const themeItems = themeResponse.data;

        const matchingTheme = themeItems.find(theme =>
            theme.maatregelen.some(themeDisposition => themeDisposition.maatregel.id === disposition.id)
        );

        if (matchingTheme) {
            return {
                ...disposition,
                themeTitle: matchingTheme.title,
                themeId: matchingTheme.id,
                color: matchingTheme.inhoud.cluster || ""
            };
        } else {
            throw new Error("Matching theme not found for disposition");
        }
    } catch (error) {
        console.error('Error fetching disposition and theme:', error);
        return null;
    }
};

export const getDispositions = async (query = "", selectedAreas = []) => {
    const trimmedQuery = query.trim();

    try {
        const [maatregelData, themeData] = await Promise.all([
            axios.get(`${process.env.REACT_APP_HOST}content/wbp6/list/maatregel?limit=300`),
            axios.get(`${process.env.REACT_APP_HOST}content/wbp6/list/thema`)
        ]);

        if (maatregelData.status === 404 || themeData.status === 404) {
            throw new Error("Resource not found: 404 error");
        }

        const maatregelItems = maatregelData.data;
        const themeItems = themeData.data;

        const updatedDispositions = [];

        for (const theme of themeItems) {
            for (const themeDisposition of theme.maatregelen) {
                for (const disposition of maatregelItems) {
                    if (themeDisposition.maatregel.id === disposition.id) {
                        updatedDispositions.push({
                            ...themeDisposition.maatregel,
                            disposition,
                            themeTitle: theme.title
                        });
                    }
                }
            }
        }

        const filteredDispositions = updatedDispositions.filter((disposition) => {
            const isMatchingQuery = (
                disposition.disposition.title.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                disposition.themeTitle.toLowerCase().includes(trimmedQuery.toLowerCase()) ||
                disposition.disposition.inhoud.toelichting.toLowerCase().includes(trimmedQuery.toLowerCase())
            );

            const isMatchingArea = selectedAreas.length === 0 || selectedAreas.some((selectedArea) => {
                return disposition.disposition.inhoud.gebied.includes(selectedArea.value);
            });

            return isMatchingQuery && isMatchingArea;
        });


        return filteredDispositions;
    } catch (error) {
        console.error("Error in getDispositions:", error.message);
        throw error;
    }
};

export const getThesaurus = async () => {
    return await axios
        .get(`${process.env.REACT_APP_HOST}meta/thesaurus`)
        .then(({ data }) => {
            return data[0]?.all;
        });
}
// Helper function to extract YouTube video ID from URL
export function YouTubeGetID(url) {
    if (!url || typeof url !== 'string') {
        console.error("Invalid URL provided");
        return null;
    }

    let ID = "";
    const parts = url
        .replace(/(>|<)/gi, "")
        .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (parts[2] !== undefined) {
        ID = parts[2].split(/[^0-9a-z_-]/i)[0];
    } else {
        console.error("Could not extract YouTube video ID from URL");
        return null;
    }
    return ID;
}

export const fetchYouTubeData = async (videoId) => {
    const API_KEY = "AIzaSyDncIess6YPfy8SFdT0MtjSGhbEkAoR0z8";

    if (!videoId) {
        console.error("Invalid YouTube ID");
        return null;
    }

    try {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${API_KEY}&part=snippet`);

        if (!response.ok) {
            throw new Error(`Error fetching video data: ${response.statusText}`);
        }

        const data = await response.json();

        // Check if the video data is available
        if (data.items && data.items.length > 0) {
            const sendData = data.items[0].snippet;
            sendData['youtube_id'] = videoId;
            return sendData; // Return the snippet of the first video found
        } else {
            throw new Error("No video data found");
        }
    } catch (error) {
        console.error("Error during fetchYouTubeData:", error.message);
        return null; // Return null in case of an error
    }
};


export const getAccessibilityData = async () => {

    try {
        const response = await axios.get(`${process.env.REACT_APP_HOST}content/wbp6/toegankelijkheidsverklaring/artikel`);
        return response.data;
    } catch (error) {
        throw new Error(error.message);
    }
}
